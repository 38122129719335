import * as React from "react";
import { Button, ButtonProps } from "../button/button";
import { cn } from "../../utils/cn";
import { ArrowDownRight, ArrowRight, ArrowUpRight, Loader2 } from "lucide-react";
import { VariantProps, cva } from "class-variance-authority";

const buttonArrowVariants = cva("group flex items-center justify-between", {
  variants: {
    size: {
      regular: "py-6 px-8 !fsc-body-lg",
      link: "py-2 justify-start !fsc-body items-center bg-transparent text-text-primary w-fit hover:bg-transparent px-0",
    },
    fullWidth: {
      true: "w-full",
      false: {
        link: "",
        regular: "md:w-[400px] w-[336px]",
      },
    },
  },
  defaultVariants: {
    size: "regular",
    fullWidth: false,
  },
});

const arrowVariants = cva("transition-transform group-hover:translate-x-1 relative", {
  variants: {
    size: {
      regular: "size-10",
      sm: "size-8",
      link: "size-4 ml-3",
    },
  },
  defaultVariants: {
    size: "regular",
  },
});

export interface ButtonArrowProps extends Omit<ButtonProps, "size">, VariantProps<typeof buttonArrowVariants> {
  arrowClassName?: string;
  fullWidth?: boolean;
  loading?: boolean;
  size?: "regular" | "link";
  direction?: "right" | "diagonal-up" | "diagonal-down";
}

const ButtonArrow = React.forwardRef<HTMLButtonElement, ButtonArrowProps>(
  (
    {
      className,
      children,
      arrowClassName,
      size = "regular",
      fullWidth = false,
      loading = false,
      direction = "right",
      ...props
    },
    ref,
  ) => {
    const ArrowIcon =
      direction === "diagonal-up" ? ArrowUpRight : direction === "diagonal-down" ? ArrowDownRight : ArrowRight;
    return (
      <Button className={cn(buttonArrowVariants({ size, fullWidth }), "max-w-full", className)} ref={ref} {...props}>
        <span className="flex-grow text-left whitespace-normal break-words">{children}</span>
        {loading && <Loader2 className="size-6 animate-spin" />}
        {!loading && <ArrowIcon className={cn(arrowVariants({ size }), arrowClassName)} strokeWidth={1} />}
      </Button>
    );
  },
);

ButtonArrow.displayName = "ButtonArrow";

export { ButtonArrow, buttonArrowVariants, arrowVariants };
